import React from "react";
import "./skills.css";
import { FaHtml5, FaCss3, FaJs, FaReact, FaJava, FaNode } from "react-icons/fa";

const Skills = () => {
  return (
    <div className="skills">
      <div className="s-container">
        <h1 className="section-heading">Mis habilidades</h1>
        <p className="s-text">
          Mis principales competencias son la arquitectura de proyectos y el
          desarrollo de software centrado en aplicaciones web y android. Gran
          parte de mi carrera profesional ha estado dedicada al software
          empresarial, por lo tanto, también realizo estas mismas actividades
          aplicadas al diseño y desarrollo backend.
        </p>
        <div className="s-detail-container">
          <FaJs />
          <FaHtml5 />
          <FaCss3 />
          <FaReact />
          <FaNode />
          <FaJava />
        </div>
      </div>
    </div>
  );
};
export default Skills;
