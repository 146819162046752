import React, { useState } from "react";
import "./contact.css";
import { FaGithub, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const initialContactData = {
  nombre: "",
  correoElectronico: "",
  mensaje: "",
};
const initialErrors = {
  nombre: false,
  correoElectronico: false,
  mensaje: false,
};

const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const Contact = () => {
  const [contactData, setContactData] = useState(initialContactData);
  const [errors, setErrors] = useState(initialErrors);
  const [successSend, setSuccessSend] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    setContactData({
      ...contactData,
      [name]: value,
    });
  };

  const handleClickSend = async () => {
    const url = "http://localhost:5000/contactDatas";
    if (isFormOk()) {
      const data = new URLSearchParams();
      for (const pair of new FormData(
        document.getElementById("contactDataForm")
      )) {
        data.append(pair[0], pair[1]);
      }
      await fetch(url, {
        method: "post",
        body: data,
      });
      setSuccessSend(true);
    }
  };

  const validateForm = () => {
    const { nombre, correoElectronico, mensaje } = contactData;
    const errorsObj = {
      ...errors,
      nombre: !nombre,
      correoElectronico:
        !correoElectronico || !EMAIL_REGEX.test(correoElectronico),
      mensaje: !mensaje,
    };
    setErrors(errorsObj);
    return errorsObj;
  };

  const hasFormErrors = (errorsObj) =>
    Object.values(errorsObj ? errorsObj : errors).some(Boolean);
  const isFormOk = () => !hasFormErrors(validateForm());

  return (
    <div id="contact" className="contact">
      <div className="cf-container">
        <h1 className="section-heading">Contacto</h1>
        <h4 className="cf-section-sub-heading">
          Dejame saber en que te puedo ayudar. Lo más pronto posible me estaré
          comunicando con usted.
        </h4>
        {/*         <form onSubmit={(e) => e.preventDefault()} noValidate id="contactForm">
          <label className="cf-label">NOMBRE</label>
          <input
            type="text"
            name="nombre"
            onChange={handleChange}
            className="cf-text-input"
            required
          />
          <label className="cf-label">CORREO ELECTRÓNICO</label>
          <input
            type="email"
            name="correoElectronico"
            onChange={handleChange}
            className="cf-text-input"
            required
          />
          <label className="cf-label">ASUNTO</label>
          <input
            type="text"
            name="asunto"
            onChange={handleChange}
            className="cf-text-input"
            required
          />
          <label className="cf-label">MENSAJE</label>
          <textarea
            name="mensaje"
            onChange={handleChange}
            className="cf-text-input"
            placeholder="¿Que proyecto tienes en mente?"
            rows={3}
            required
          ></textarea>
          {hasFormErrors() && (
            <div className="errors">
              {errors.nombre && (
                <div className="error-field">Nombre no diligenciado!</div>
              )}
              {errors.correoElectronico && (
                <div className="error-field">
                  Correo electrónico no diligenciado o no válido!
                </div>
              )}
              {errors.mensaje && (
                <div className="error-field">Mensaje no diligenciado!</div>
              )}
            </div>
          )}
          {successSend && (
            <div className="success-send">
              <p>Reserva enviada exitosamente!</p>
              <p>
                Estaremos confirmando disponibilidad a través del correo
                electrónico o WhatsApp.
              </p>
            </div>
          )}
          <div className="cf-foot">
            <button
              type="button"
              style={{ cursor: "pointer" }}
              className="cf-button"
              onClick={handleClickSend}
            >
              ENVIAR
            </button>
          </div>
        </form> */}
        <div className="icons-container">
          <a href="http://www.github.com/BarriosFreddy" target="_blank">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/freddybarrios/" target="_blank">
            <FaLinkedin />
          </a>
          <a href="https://wa.me/573052665062" target="_blank">
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
