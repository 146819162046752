import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="home-footer">
      <footer className="home-footer1">
        <div className="home-container6">
          <span className="home-logo1">FREDDY BARRIOS</span>
          <nav className="home-nav1">
            <a href="#home" className="footer-link">
              INICIO
            </a>
            <a href="#about" className="footer-link">
              SOBRE MI
            </a>
            <a href="#portfolio" className="footer-link">
              PORTFOLIO
            </a>
            <a href="#contact" className="footer-link">
              CONTACTO
            </a>
          </nav>
        </div>
        <div className="home-separator"></div>
        <div className="home-container7">
          <span className="home-text11">© 2023, All Rights Reserved.</span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
