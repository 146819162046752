import React from "react";
import "./about.css";

const About = () => {
  return (
    <div id="about" className="about">
      <div className="about1">
        <div className="container2">
          <h2 className="details-heading heading2">Sobre mi</h2>
          <p className="details-sub-heading">
            Hola, Soy Freddy Barrios, Desarrollador de Software Fullstack con
            una pasión por crear sitios y aplicaciones web efecientes. Con los
            años de experiencia que he acumulado en la industria de la
            tecnologia, he desarrollado mis habilidades para ayudarte a traer
            tus ideas a la vida.
          </p>
          <p className="details-sub-heading">
            ¡Pasa y echa un vistazo a mis últimos proyectos!
          </p>
        </div>
        <img
          alt="image"
          src="assets/photo.jpg"
          className="details-image"
        />
      </div>
    </div>
  );
};

export default About;
