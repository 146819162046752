import React from "react";
import { Helmet } from "react-helmet";
import "./home.css";

import Header from "../components/header/Header";
import Welcome from "../components/welcome/Welcome";
import About from "../components/about/About";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "./../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Skills from "../components/skills/Skills";

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Freddy Barrios Dev</title>
        <meta property="og:title" content="Freddy Barrios" />
      </Helmet>
      <Header />
      <Welcome />
      <About />
      <Portfolio />
      <Skills />
      {/*       <Testimonials /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
