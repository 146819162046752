import React from "react";
import "./welcome.css";

const Welcome = () => {
  return (
    <div id="home" className="welcome">
      <div className="welcome-container">
        <h1 className="welcome-heading">
          <p>Software Developer</p>
          <p>Fullstack</p>
        </h1>
        <span className="welcome-sub-heading">
          Creo aplicaciones que te ayudan alcanzar tus objetivos
        </span>
      </div>
    </div>
  );
};

export default Welcome;
