import React from "react";

import PropTypes from "prop-types";

import "./portfolio-card.css";

const PortfolioCard = (props) => {
  return (
    <a
      href={props.href}
      target="_blank"
      className={`portfolio-card ${props.rootClassName} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="portfolio-card-image"
      />
      <div className="subtitle">
        <p>{props.subtitle}</p>
      </div>
    </a>
  );
};

PortfolioCard.defaultProps = {
  href: "",
  image_src: "assets/img1.jpg",
  image_alt: "image",
  rootClassName: "",
};

PortfolioCard.propTypes = {
  href: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default PortfolioCard;
