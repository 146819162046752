import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="header">
      <header data-thq="thq-navbar" className="navbar-interactive">
        <div data-thq="thq-navbar-nav" className="desktop-menu">
          <a href="/" className="text-logo">
            FREDDY BARRIOS
          </a>
          <nav className="links">
            <a href="#home">INICIO</a>
            <a href="#about" className="nav-link">
              SOBRE MI
            </a>
            <a href="#portfolio" className="nav-link">
              PORTFOLIO
            </a>
            <a href="#contact" className="nav-link">
              CONTACTO
            </a>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="burger-menu">
          {/* <svg viewBox="0 0 1024 1024" className="icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg> */}
          <a href="/" className="text-logo">
            FREDDY BARRIOS
          </a>
        </div>
        <div data-thq="thq-mobile-menu" className="mobile-menu">
          <div className="nav">
            <div className="top">
              <a href="/" className="logo">
                FREDDY BARRIOS
              </a>
              <div data-thq="thq-close-menu" className="close-menu">
                <svg viewBox="0 0 1024 1024" className="icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="links1">
              <a href="#home" className="nav-link">
                INICIO
              </a>
              <a href="#about" className="nav-link">
                SOBRE MI
              </a>
              <a href="#portfolio" className="nav-link">
                PORTFOLIO
              </a>
              <a href="#contact" className="nav-link">
                CONTACTO
              </a>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
